import React from 'react'
import AnimeCard from './AnimeCard';
import { useState, useEffect } from 'react';

function MainContent(props) {

    return (
        <main>

        </main>
    )
}

export default MainContent